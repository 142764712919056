import PropTypes from 'prop-types';

/**
 * Defines data which is necessary to render/display an image correctly
 * Contains backend/cdn logic which should not appear in Hydra, but requires a breaking change which needs to be fixed
 */
const image = PropTypes.shape({
  altText: PropTypes.string,
  filename: PropTypes.string,
  hashCode: PropTypes.string,
  targetFormat: PropTypes.string,
});

/**
 * Defines the structure of a glyph for the Icon component.
 */
const glyph = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
);

/**
 * Defines data which is necessary to render/display a video correctly
 * Contains backend/cdn logic which should not appear in Hydra, but requires a breaking change which needs to be fixed
 */
const video = PropTypes.shape({
  /* The alt-text for the poster-image */
  altText: PropTypes.string,
  /* The real filename. Hybris returns it as 'realFilename' */
  filename: PropTypes.string,
  /* Needed to create a url. Hybris returns it as 'cdnFilename' filename */
  hashCode: PropTypes.string.isRequired,
});

/**
 * React Ref type
 */
const ref = PropTypes.oneOfType([
  PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.object,
  }),
  PropTypes.func,
]);

/**
 * Image aspect ratio, example: 16:9 or 4:3 etc
 */
const aspectRatio = (props, propName, componentName) => {
  if (props[propName] && !/\d+:\d+/.test(props[propName])) {
    return new Error(
      `Invalid value "${props[propName]}" for prop "${propName}" supplied to "${componentName}".`,
    );
  }
  return null;
};

/** One of a list of predefined types of the HTML input element. */
const inputType = PropTypes.oneOf([
  'checkbox',
  'email',
  'hidden',
  'multiline',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'url',
  'date',
]);

/** One of a list of predefined input modes of the HTML input element. */
const inputMode = PropTypes.oneOf([
  'decimal',
  'email',
  'none',
  'numeric',
  'search',
  'tel',
  'text',
  'url',
]);

export default {
  aspectRatio,
  image,
  glyph,
  video,
  ref,
  inputType,
  inputMode,
};
