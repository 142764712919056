function getOffsetToParent(element, parent) {
  const offset = {
    top: 0,
    left: 0,
  };

  let currentElement = element;

  while (currentElement !== parent && currentElement !== null) {
    offset.top += currentElement.offsetTop;
    offset.left += currentElement.offsetLeft;

    currentElement = currentElement.offsetParent;
  }

  return offset;
}

function getScrollParent(element) {
  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = /(auto|scroll)/;

  if (style.position === 'fixed') {
    return document.body;
  }

  let parent = element.parentElement;

  while (parent) {
    style = getComputedStyle(parent);

    const irrelevantStaticElement =
      excludeStaticParent && style.position === 'static';
    const hasScrolling = overflowRegex.test(
      style.overflow + style.overflowY + style.overflowX,
    );

    if (!irrelevantStaticElement && hasScrolling) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return document.body;
}

function scrollHorizontally(element, scrollParent, options) {
  const offset = getOffsetToParent(element, scrollParent);
  const contentIsLeftOutside = scrollParent.scrollLeft > offset.left;
  const currentRightBoundary =
    scrollParent.scrollLeft + scrollParent.clientWidth - options.offsetX;
  const newRightBoundary = offset.left + element.clientWidth;
  const contentIsRightOutside = currentRightBoundary < newRightBoundary;

  if (contentIsLeftOutside) {
    scrollParent.scrollLeft = offset.left - options.offsetX; // eslint-disable-line no-param-reassign
  } else if (contentIsRightOutside) {
    const diffToScroll = newRightBoundary - currentRightBoundary;

    scrollParent.scrollLeft += diffToScroll; // eslint-disable-line no-param-reassign
  }
}

function scrollVertically(element, scrollParent, options) {
  const offset = getOffsetToParent(element, scrollParent);
  const contentIsTopOutside = scrollParent.scrollTop > offset.top;
  const currentBottomBoundary =
    scrollParent.scrollTop + scrollParent.clientHeight - options.offsetY;
  const newBottomBoundary = offset.top + element.clientHeight;
  const contentIsBottomOutside = currentBottomBoundary < newBottomBoundary;

  if (contentIsTopOutside) {
    scrollParent.scrollTop = offset.top - options.offsetY; // eslint-disable-line no-param-reassign
  } else if (contentIsBottomOutside) {
    const diffToScroll = newBottomBoundary - currentBottomBoundary;

    scrollParent.scrollTop += diffToScroll; // eslint-disable-line no-param-reassign
  }
}

export function scrollIntoView(element, scrollingOptions = {}) {
  const options = {
    horizontal: false,
    offsetX: 24,
    offsetY: 0,
    vertical: false,
    ...scrollingOptions,
  };

  const scrollParent = getScrollParent(element);

  if (
    options.horizontal &&
    scrollParent.scrollWidth > scrollParent.clientWidth
  ) {
    scrollHorizontally(element, scrollParent, options);
  }

  if (
    options.vertical &&
    scrollParent.scrollHeight > scrollParent.clientHeight
  ) {
    scrollVertically(element, scrollParent, options);
  }
}

function getScrollingClassname(type) {
  switch (type) {
    case 'mobile':
      return 'noScrollingMobile';
    case 'desktop':
      return 'noScrollingDesktop';
    default:
      return 'noScrolling';
  }
}

let lastScrollPostion = 0;

export function disableScrolling(type = 'both') {
  lastScrollPostion = window.pageYOffset;

  const usedClass = getScrollingClassname(type);

  document.body.classList.add(usedClass);
  if (type === 'mobile' || type === 'dekstop') {
    document.documentElement.classList.add(usedClass);
  }
}

export function enableScrolling(preserveScrollPosition, type = 'both') {
  const usedClass = getScrollingClassname(type);

  document.body.classList.remove(usedClass);
  if (type === 'mobile' || type === 'desktop') {
    document.documentElement.classList.remove(usedClass);
  }

  if (preserveScrollPosition) {
    window.scrollTo(0, lastScrollPostion);
  }
}
