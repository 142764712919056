export function checkType(type) {
  if (typeof type === 'string') {
    if (
      /(multipart\/x-zip|application\/(zip|x-zip)(-compressed)?)/.test(type)
    ) {
      return 'zip';
    }
    if (/image\/(jpe?g|png|gif)/.test(type)) {
      return 'image';
    }
    if (/application\/pdf/.test(type)) {
      return 'pdf';
    }
    if (
      /application\/vnd\.(openxmlformats-officedocument\.spreadsheetml\.sheet|ms-excel)/.test(
        type,
      )
    ) {
      return 'excel';
    }
    if (
      /application\/(vnd\.openxmlformats-officedocument\.wordprocessingml\.document|msword)/.test(
        type,
      )
    ) {
      return 'word';
    }
    if (
      /application\/vnd\.oasis\.opendocument\.(text|spreadsheet)/.test(type)
    ) {
      return 'openoffice';
    }
    return 'other';
  }
  return null;
}
