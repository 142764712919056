// all attributed MUST be written in lowercase as defined on MDN
const elementTypes = {
  globals: [
    'accesskey',
    'autocapitalize',
    'autofocus',
    'class',
    'classname',
    'contenteditable',
    'dir',
    'draggable',
    'enterkeyhint',
    'exportparts',
    'hidden',
    'id',
    'inert',
    'inputmode',
    'is',
    'itemid',
    'itemprop',
    'itemref',
    'itemscope',
    'itemtype',
    'lang',
    'nonce',
    'part',
    'popover',
    'ref',
    'role',
    'slot',
    'spellcheck',
    'style',
    'tabindex',
    'title',
    'translate',
    'virtualkeyboardpolicy',
  ],
  button: [
    'autofocus',
    'disabled',
    'form',
    'formaction',
    'formenctype',
    'formmethod',
    'formnovalidate',
    'formtarget',
    'href', // used for <Link as={} />
    'name',
    'rel', // used for <Link as={} />
    'replace', // used for <Link as={} />
    'popovertarget',
    'popovertargetaction',
    'target', // used for <Link as={} />
    'to', // used for <Link as={} />
    'type',
    'value',
  ],
  input: [
    'accept',
    'alt',
    'autocapitalize',
    'autocomplete',
    'autofocus',
    'capture',
    'checked',
    'defaultvalue',
    'dirname',
    'disabled',
    'form',
    'formaction',
    'formenctype',
    'formmethod',
    'formnovalidate',
    'formtarget',
    'height',
    'id',
    'inputmode',
    'list',
    'max',
    'maxlength',
    'min',
    'minlength',
    'multiple',
    'name',
    'pattern',
    'placeholder',
    'popovertarget',
    'popovertargetaction',
    'readonly',
    'required',
    'size',
    'src',
    'step',
    'tabindex',
    'title',
    'type',
    'value',
    'width',
  ],
  textarea: [
    'autocapitalize',
    'autocomplete',
    'autocorrect',
    'on',
    'off',
    'autofocus',
    'cols',
    'dirname',
    'disabled',
    'form',
    'maxlength',
    'minlength',
    'name',
    'placeholder',
    'readonly',
    'required',
    'rows',
    'spellcheck',
    'wrap',
  ],
};

const filterInvalidHTMLAttributes = (
  elementType: string,
  attributes: Record<string, unknown>,
) => {
  const filteredAttributes: Record<string, unknown> = {};

  const attributePairs = Object.entries(attributes);

  attributePairs.forEach(([name, value]) => {
    if (
      value !== undefined &&
      (name.startsWith('aria') ||
        name.startsWith('data') ||
        name.startsWith('on') ||
        elementTypes[elementType as keyof typeof elementTypes].includes(
          name.toLowerCase(),
        ) ||
        elementTypes.globals.includes(name.toLowerCase()))
    ) {
      filteredAttributes[name] = value;
    }
  });

  return filteredAttributes;
};

export default filterInvalidHTMLAttributes;
