/**
 * This RegEx looks for letters (including special letters and trailing dot) in the <base> and looks for numbers in the <power>.
 * It can handle units composed by two words such as 'četvornih metara'. Example m2: if the unit prop passed to <Price /> is m2,
 * m will be the base and 2 will be the power (power is displayed as supertext in Price component). Example kom. base: the dot will
 * be included in the returned base. Unicode flag is not used here as it's not supported in Firefox as of yet.
 * https://stackoverflow.com/questions/280712/javascript-unicode-regexes/37668315#37668315
 */

export const unitRegex =
  /^(?<base>[\p{Letter}\\.\p{White_Space}]+)(?<power>[0-9]?)$/u;
